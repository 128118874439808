






























import { Inject } from 'inversify-props';
import { Vue, Component } from 'vue-property-decorator';
import DeepAnalysisItem from '@/modules/deep-analysis/components/hotel/deep-analysis-item.vue';
import DeepCompsetAnalysisService, { DeepCompsetAnalysisServiceS } from '@/modules/deep-analysis/deep-analysis.service';
import COMPARE_TO from '../../constants/compare-to-filter.constant';

@Component({
    components: {
        DeepAnalysisItem,
    },
})
export default class DeepAnalysisList extends Vue {
    @Inject(DeepCompsetAnalysisServiceS) deepCompsetAnalysisService!: DeepCompsetAnalysisService;

    get comparedValueName() {
        const { compareTo } = this.deepCompsetAnalysisService;
        return compareTo === COMPARE_TO.COMPSET_AVG ? 'CompSet Avg' : 'Last Year';
    }

    get skeleton() {
        const { isLoading, data } = this.deepCompsetAnalysisService;
        const { length: isHaveData } = data;

        if (isHaveData || (!isHaveData && !isLoading)) return false;
        return this.deepCompsetAnalysisService.isLoading;
    }
}
