



























import UserService, { UserServiceS } from '@/modules/user/user.service';
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import DateDocumentFilter from '@/modules/document-filters/components/date-document-filter.vue';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';
import FEATURES from '@/modules/common/constants/features.constant';
// import CurrencySwitcher from '@/modules/common/components/currency-switcher.vue';

@Component({
    components: {
        DateDocumentFilter,
        // CurrencySwitcher,
    },
})
export default class DeepAnalysisHeader extends Vue {
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(HotelsServiceS) private hotelsService!: HotelsService;

    infoTooltip: boolean = false;

    get isChainOrCluser() {
        return this.userService.isViewAsChain;
    }

    get hotelName(): string | null {
        const { currentHotelId } = this.userService;

        if (!currentHotelId) {
            return null;
        }
        return this.hotelsService.getHotelName(currentHotelId);
    }
    get feature() {
        return FEATURES.DEEP_COMPSET;
    }
}
