












import COMPARE_TO from '@/modules/deep-analysis/constants/compare-to-filter.constant';
import DeepCompsetAnalysisService, { DeepCompsetAnalysisServiceS } from '@/modules/deep-analysis/deep-analysis.service';
import { Inject } from 'inversify-props';
import moment from 'moment';
import { Component, Vue } from 'vue-property-decorator';

@Component({
    filters: {
        DateFilter: (date: Date) => moment(date).format('MMM, D YYYY'),
    },
})
export default class DeepAnalysisInfo extends Vue {
    @Inject(DeepCompsetAnalysisServiceS) deepCompsetAnalysisService!: DeepCompsetAnalysisService;

    get text() {
        const isBook = this.deepCompsetAnalysisService.showBy.startsWith('book');
        return isBook
            ? 'were made during the last 7 days'
            : 'will stay at your property during the next 90 days';
    }

    get comparedValueName() {
        const { compareTo } = this.deepCompsetAnalysisService;
        return compareTo === COMPARE_TO.COMPSET_AVG ? 'CompSet Avg' : 'Last Year';
    }

    get skeleton() {
        return this.deepCompsetAnalysisService.isLoading;
    }
}

