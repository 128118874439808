



















import DeepAnalysisInfo from '@/modules/deep-analysis/components/hotel/deep-analysis-info.vue';
import { Vue, Component } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import DeepCompsetAnalysisService, { DeepCompsetAnalysisServiceS } from '@/modules/deep-analysis/deep-analysis.service';
import PageWrapper from '@/modules/common/components/page-wrapper.vue';
import DeepAnalysisHeader from '@/modules/deep-analysis/components/hotel/deep-analysis-header.vue';
import DeepAnalysisActions from '@/modules/deep-analysis/components/hotel/deep-analysis-actions.vue';
import DeepAnalysisList from '@/modules/deep-analysis/components/hotel/deep-analysis-list.vue';

@Component({
    components: {
        PageWrapper,
        DeepAnalysisHeader,
        DeepAnalysisActions,
        DeepAnalysisList,
        DeepAnalysisInfo,
    },
})
export default class DeepAnalysisPage extends Vue {
    @Inject(DeepCompsetAnalysisServiceS) deepCompsetAnalysisService!: DeepCompsetAnalysisService;

    contentHeight: string | null = null;

    mounted() {
        this.$nextTick(() => {
            this.calcHeight();
        });
        const wrapper = this.$refs.wrapper as HTMLElement;
        if (wrapper) {
            wrapper.scrollTop = 0;
        }
    }

    calcHeight() {
        const el: HTMLElement | null = document.querySelector('.deep-analysis');
        const footer: HTMLElement | null = document.querySelector('footer');
        if (el && footer) {
            this.contentHeight = 'calc(100vh - 70px)';
        }
    }
}
